import React, { createContext, useContext, useMemo } from 'react';
import { PageContext } from '../utils/PageProps';

export type LocaleLinks = Array<{ locale?: PageContext['locale']; url?: string }>;

const SITE_MAIN_LANGUAGE = process.env.GATSBY_SITE_MAIN_LANGUAGE as PageContext['locale'] ?? 'sv'; 

const CurrentLocaleContext = createContext<{
  locale: PageContext['locale'];
  links: LocaleLinks;
}>({
  locale: SITE_MAIN_LANGUAGE,
  links: [],
});

export const CurrentLocaleProvider: React.FC<{
  locale?: PageContext['locale'];
  alternativeLinks?: LocaleLinks;
}> = ({ children, locale, alternativeLinks }) => {
  if (!locale || !alternativeLinks) return <>{children}</>;

  const value = useMemo(() => {
    return {
      locale,
      links: alternativeLinks.slice().sort((a, b) => {
        if (a.locale === 'sv') return -1;
        if (a.locale === 'pl') return 1;

        return 0;
      }),
    };
  }, [locale, alternativeLinks]);

  return <CurrentLocaleContext.Provider value={value}>{children}</CurrentLocaleContext.Provider>;
};

export const useLocale = () => {
  return useContext(CurrentLocaleContext).locale || process.env.SITE_MAIN_LANGUAGE;
};

export const useLocaleLinks = () => {
  return useContext(CurrentLocaleContext).links;
};
