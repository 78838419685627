import { Link } from 'gatsby';
import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { themes } from '../theme/theme';
import { getLocalizedUri } from '../utils/getLocalizedLink';
import { NavPage } from '../__generated__/graphql';
import { Box } from './common/Box';
import { Logo } from './common/Logo';
import { Navigation } from './common/Navigation';
import { useLocale } from './CurrentLocale';
import { Menu } from './Menu';
import { useCurrentTheme } from './ThemeChanger';
import LanguageSwitch from './LanguageSwitch';
import { PageContext } from '../utils/PageProps';
import { getSupportedLanguages } from '../utils/languages';

export type HeaderProps = {
  siteTitle?: string;
  navigation: NavPage[];
  forceHeaderTheme?: keyof typeof themes | false;
  location: Location;
  locale: PageContext['locale'];
};

const MAIN_LANGUAGE = process.env.GATSBY_SITE_MAIN_LANGUAGE as string;
const SUPPORTED_LANGUAGES = getSupportedLanguages();

// TODO: Remove background in sticky menu and fade from dark -> light
const Header: React.FC<HeaderProps> = ({
  siteTitle = '',
  navigation,
  forceHeaderTheme = false,
  location,
  locale
}) => {
  const currentTheme = useCurrentTheme();
  const [active, setActive] = useState(false);
  const theme = forceHeaderTheme ? themes[forceHeaderTheme] : currentTheme;

  return (
    <ThemeProvider theme={theme}>
      <Box
        as="header"
        position="fixed"
        top={0}
        left={0}
        right={0}
        py={[2, 3]}
        px={[3, 0, 3, 4, 5]}
        zIndex={99}
        width="100%"
        borderBottom={`solid 1px ${theme.colors.inverseBg}`}
        bg={[theme.colors.bg]}
        color={theme.colors.text}
      >
        <Box alignItems="center" display="flex">
          <Box display="inline-flex" flexGrow="1" gridColumn="1">
            <LogoComponent />
          </Box>
          <Box
            gridColumn={['2 / -2']}
            display="inline-flex"
            alignItems="center"
          >
            <Box mr="0" ml="auto">
              <Menu active={active} onActiveChange={setActive}>
                <Navigation pages={navigation} active={active} />
                <LanguageSwitch 
                  pages={navigation}
                  location={location} 
                  locale={locale} 
                  supportedLanguages={SUPPORTED_LANGUAGES}
                  mainLanguage={MAIN_LANGUAGE}
                />
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const LogoComponent: React.FC = () => {
  const locale = useLocale();

  return (
    <Box flexShrink={0} px={[0, 2, 0]}>
      <Link to={getLocalizedUri('/', locale)} title={TITLE_MAP[locale]} >
        <Logo maxWidth={[70, 100]} />
      </Link>
    </Box>
  );
};

const TITLE_MAP = {
  'sv': 'Till startsidan',
  'en': 'To start page',
  'dk': 'TODO'
};

export default Header;
