export const sv = {
  office: 'Kontor',
  next: 'Nästa',
  stockholm: 'Stockholm',
  sundsvall: 'Sundsvall',
  krakow: 'Kraków',
  cookies:
    'Vi använder kakor för en bättre upplevelse. Surfar du vidare så tycker du det är ok eller läs vår ',
  cookiesLink: 'policy här',
  privacyPolicy: 'Integritetspolicy',
  cookiesPolicy: 'Cookies Policy',
  goToArticles: 'Visa alla artiklar',
  applyForJob: 'Sök tjänsten',
  emailMe: 'Maila mig',
  fieldRequired: 'This field is required.',
  incorrectEmail: 'This email address is not valid.',
  incorrectPhone: 'This phone number is not valid.',
  messageIsToLong: 'Message is too long',
  show: 'Show',
  thankYou: 'Tack, du är nu anmäld',
  similarPlays: 'Liknande innehåll',
  goToPlays: 'Visa allt innehåll',
  visitMyProfile: 'Besök min profil',
  login: 'Logga in',
  sent: 'Skickat',
  copenhagen: 'Copenhagen'
};

export const en: Translations = {
  office: 'Office',
  next: 'Next',
  stockholm: 'Stockholm',
  sundsvall: 'Sundsvall',
  krakow: 'Kraków',
  cookies: 'We use cookies for a better experience. By browsing further you accept our ',
  cookiesLink: 'use of cookies',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',
  goToArticles: 'Show all articles',
  applyForJob: 'Apply for this job',
  emailMe: 'Email me',
  fieldRequired: 'This field is required.',
  incorrectEmail: 'This email address is not valid.',
  incorrectPhone: 'This phone number is not valid.',
  messageIsToLong: 'Message is too long',
  show: 'Show',
  thankYou: 'Thank you',
  similarPlays: 'Similar content',
  goToPlays: 'Show all videos',
  visitMyProfile: 'Visit my profile',
  login: 'Login',
  sent: 'Sent',
  copenhagen: 'Copenhagen'
};

export const dk: Translations = {
  office: 'Office',
  next: 'Next',
  stockholm: 'Stockholm',
  sundsvall: 'Sundsvall',
  krakow: 'Kraków',
  cookies: 'We use cookies for a better experience. By browsing further you accept our ',
  cookiesLink: 'use of cookies',
  privacyPolicy: 'Privacy Policy',
  cookiesPolicy: 'Cookies Policy',
  goToArticles: 'Show all articles',
  applyForJob: 'Apply for this job',
  emailMe: 'Email me',
  fieldRequired: 'This field is required.',
  incorrectEmail: 'This email address is not valid.',
  incorrectPhone: 'This phone number is not valid.',
  messageIsToLong: 'Message is too long',
  show: 'Show',
  thankYou: 'Thank you',
  similarPlays: 'Similar content',
  goToPlays: 'Show all videos',
  visitMyProfile: 'Visit my profile',
  login: 'Login',
  sent: 'Sent',
  copenhagen: 'Copenhagen'
}

export const pl: Translations = {
  office: 'Gabinet',
  next: 'Kolejny',
  stockholm: 'Sztokholm',
  sundsvall: 'Sundsvall',
  krakow: 'Kraków',
  cookies: '',
  cookiesLink: '',
  privacyPolicy: '',
  cookiesPolicy: 'Cookies Policy',
  goToArticles: '',
  applyForJob: '',
  emailMe: '',
  fieldRequired: 'To pole jest wymagane.',
  incorrectEmail: 'Podany adres email nie jest poprawny.',
  incorrectPhone: 'Podany numer telefonu nie jest poprawny.',
  messageIsToLong: 'Wiadomość jest za długa.',
  show: 'Pokaż',
  thankYou: 'Dziękuję',
  similarPlays: 'Podobna treść',
  goToPlays: 'Pokaż wszystkie filmy',
  visitMyProfile: 'Odwiedź mój profil',
  login: 'Zaloguj',
  sent: 'Wysłano',
  copenhagen: 'Copenhagen'
};

export type Translations = typeof sv;
