import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { NavPage, SupportedLanguagesQuery } from '../__generated__/graphql';
import { Box } from './common/Box';
import { Text } from './common/Text';
import styled from 'styled-components';
import { PageContext } from '../utils/PageProps';

type Language = {
  locale: PageContext['locale'];
  url: string;
};

const LanguageSwitch: React.FC<{
  pages: NavPage[];
  location: Location;
  locale: PageContext['locale'];
  supportedLanguages: string[];
  mainLanguage: string;
}> = ({ pages, location, locale, supportedLanguages, mainLanguage }) => {
  const languages: Language[] = [
    {
      locale: 'sv',
      url: '/sv',
    },
    {
      locale: 'dk',
      url: '/dk/',
    },
    {
      locale: 'en',
      url: '/en/',
    },
  ]
    .filter(language =>
      supportedLanguages.length === 1 ? false : supportedLanguages.includes(language.locale)
    )
    .map(
      language =>
        ({
          ...language,
          url: mainLanguage === language.locale ? '/' : language.url,
        } as Language)
    );

  const { articles } = useStaticQuery<SupportedLanguagesQuery>(query);

  const getArticleSlug = (selectedLocale: PageContext['locale']) => {
    let pathname = location.pathname;

    for (let index in articles.nodes) {
      let article = articles.nodes[index];
      let slug = article.slug?.[locale];

      if (slug === null) {
        continue;
      }

      if (pathname.indexOf('/' + slug?.current) < 0) {
        continue;
      }

      if (article.slug?.[selectedLocale] === null) {
        continue;
      }

      return article.slug?.[selectedLocale]?.current;
    }

    return '';
  };

  const getPath = (selectedLocale: PageContext['locale']) => {
    for (let index in pages) {
      const page = pages[index];
      const _slug = page.__typename === 'SanityPage' ? page.slug : page.relativeUrl;

      let slug = _slug?.[locale];

      if (typeof slug === 'undefined' || slug === null) {
        continue;
      }

      let pathname = location.pathname;

      if (pathname.indexOf('/' + slug) < 0) {
        continue;
      }

      if (_slug?.[selectedLocale] === null) continue;

      let path =
        _slug?.__typename === 'SanityLocaleSlug'
          ? _slug?.[selectedLocale]?.current
          : _slug?.[selectedLocale];

      if (pathname.indexOf(slug + '/') >= 0) {
        path += '/' + getArticleSlug(selectedLocale);
      }

      return path;
    }

    return '';
  };

  return (
    <Box display="flex" ml={[0, 0, 4]} mb={[4, 4, 0]} alignSelf={['center', 'center', 'initial']}>
      {languages.map(language => {
        let url = language.url + getPath(language.locale);
        return (
          <LangLinkWrapper key={url}>
            <LangLink to={url}>
              <Text
                fontSize={1}
                fontWeight={language.locale === locale ? 400 : 300}
                color={language.locale === locale ? 'text' : 'text4'}
                textTransform="uppercase"
              >
                {language.locale}
              </Text>
            </LangLink>
          </LangLinkWrapper>
        );
      })}
    </Box>
  );
};

const LangLinkWrapper = styled(Box)`
  display: flex;
  z-index: 1;
  &:not(:last-child):after {
    content: '|';
    color: ${props => props.theme.colors.text4};
    display: block;
    font-weight: 300;
    margin: 0 0.5rem;
  }
`;

const LangLink = styled(Link)`
  text-decoration: none;
`;

const query = graphql`
  query SupportedLanguagesQuery {
    sanityConfig {
      supportedLanguages
    }

    articles: allSanityArticle {
      nodes {
        slug {
          en {
            current
          }
          sv {
            current
          }
          dk {
            current
          }
        }
      }
    }
  }
`;

export default LanguageSwitch;
