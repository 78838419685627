import { PageContext } from './PageProps';

export type SlugTypes = 'tag' | 'case' | 'article' | 'page' | 'esatto-play';

const slugPrefix: { [key in SlugTypes]: string } = {
  tag: 'tags',
  case: 'cases',
  article: 'stuff',
  page: '',
  'esatto-play': 'esatto-play',
};

const localePrefixMap: { [key in PageContext['locale']]: string } = {
  en: 'en',
  dk: 'dk',
  sv: 'sv',
};

/**
 * Creates complete link from slug, type and locale.
 *
 * A case with english locale -> /en/cases/slug-here
 */
export const addURLPrefix = (
  slug: string | null | undefined,
  type: SlugTypes,
  locale: PageContext['locale']
): string => {
  if (slug == null) return '';

  const prefix = slugPrefix[type];
  const localePrefix =
    locale === process.env.GATSBY_SITE_MAIN_LANGUAGE ? '' : localePrefixMap[locale];
  return removeDoubleSlash(`/${localePrefix}/${prefix}/${slug}`);
};

const removeDoubleSlash = (url: string): string => {
  return url.replace(/\/{2,}/g, '/');
};
